@use '@angular/material' as mat;
@mixin mc-mat-slide-toggles-theme($theme, $typography) {
  .mat-slide-toggle {
    @include _mc-mat-slide-toggle($theme, 'primary');
    @include _mc-mat-slide-toggle($theme, 'secondary');
  }
}

@mixin _mc-mat-slide-toggle($theme, $palette-name) {
  $palette: map-get($theme, $palette-name);

  &.mat-#{$palette-name} {
    @include _mc-mat-slide-toggle-hue($palette, default);
  }

  &.mat-#{$palette-name}-muted {
    @include _mc-mat-slide-toggle-hue($palette, lighter);
  }

  &.mat-#{$palette-name}-vivid {
    @include _mc-mat-slide-toggle-hue($palette, darker);
  }

  // &.mat-#{$palette-name} {
  //   .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  //     background-color: mat-color($palette, default);
  //   }

  //   .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  //     background-color: mat-color($palette, default, .54);
  //   }

  //   .mat-ripple-element {
  //     background-color: mat-color($palette, default);
  //   }
  // }

  // &.mat-#{$palette-name}-muted {
  //   .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  //     background-color: mat-color($palette, lighter);
  //   }

  //   .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  //     background-color: mat-color($palette, lighter, .54);
  //   }

  //   .mat-ripple-element {
  //     background-color: mat-color($palette, lighter);
  //   }
  // }

  // &.mat-#{$palette-name}-vivid {
  //   .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  //     background-color: mat-color($palette, darker);
  //   }

  //   .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  //     background-color: mat-color($palette, darker, .54);
  //   }

  //   .mat-ripple-element {
  //     background-color: mat-color($palette, lighter);
  //   }
  // }
}

@mixin _mc-mat-slide-toggle-hue($palette, $hue) {
  &.mc-slide-toggle-always-colored,
  &.mat-checked {
    .mat-slide-toggle-thumb {
      background-color: mat.get-color-from-palette($palette, $hue);
    }
  }

  &.mc-slide-toggle-always-colored,
  &.mat-checked {
    .mat-slide-toggle-bar {
      background-color: mat.get-color-from-palette($palette, $hue, .54);
    }
  }

  &.mc-slide-toggle-always-colored .mat-ripple-element,
  .mat-ripple-element {
    background-color: mat.get-color-from-palette($palette, $hue);
  }
}
