@use '@angular/material' as mat;
@mixin mc-mat-toolbars-theme($theme, $typography) {
  .mat-toolbar {
    @include _mc-mat-toolbar-complex($theme, 'secondary', 'secondary-invert', 'secondary-invert');
    @include _mc-mat-toolbar($theme, 'secondary-invert');
    @include _mc-mat-toolbar($theme, 'caution');
    @include _mc-mat-toolbar($theme, 'notice');
    @include _mc-mat-toolbar($theme, 'basic', 200, '200-contrast');

    // Set the typography shared throughout the toolbar
    &,
    .mat-tab-link {
      @include mat.typography-level($typography, navbar);
    }
  }
}

@mixin _mc-mat-toolbar-complex($theme, $toolbar-bg-palette-name, $toolbar-fg-palette-name, $toolbar-link-palette-name) {
  $background: map-get($theme, background);
  $bg-palette: map-get($theme, $toolbar-bg-palette-name);
  $fg-palette: map-get($theme, $toolbar-fg-palette-name);
  $link-palette: map-get($theme, $toolbar-link-palette-name);

  &.mat-#{$toolbar-bg-palette-name} {
    background: mat.get-color-from-palette($bg-palette, default);
    color: mat.get-color-from-palette($fg-palette, default);

    a:not(.mat-button-base),
    .mat-tab-link,
    .mc-link,
    .mat-link.mc-inline-button:not(.mc-inline-button-disabled) {
      opacity: 1;
      color: mat.get-color-from-palette($link-palette, default);
    }

    .mat-tab-link:focus,
    .mat-tab-link:hover,
    .mat-tab-link.cdk-focused {
      &:not(.mat-tab-disabled) {
        background: mat.get-color-from-palette($bg-palette, darker);
      }
    }

    // .mat-button,
    // .mat-icon-button,
    // .mat-stroked-button,
    // .mat-flat-button {
    //   .mat-button-focus-overlay {
    //     background: mat.get-color-from-palette($bg-palette, darker);
    //   }

    //   &.cdk-keyboard-focused,
    //   &.cdk-program-focused {
    //     .mat-button-focus-overlay {
    //       opacity: 1;
    //     }
    //   }
    // }
  }

  &.mat-#{$toolbar-bg-palette-name}-vivid {
    background: mat.get-color-from-palette($bg-palette, darker);
    color: mat.get-color-from-palette($fg-palette, default);

    a:not(.mat-button-base),
    .mat-tab-link,
    .mc-link,
    .mat-link.mc-inline-button:not(.mc-inline-button-disabled) {
      opacity: 1;
      color: mat.get-color-from-palette($link-palette, default);
    }

    .mat-tab-link:focus,
    .mat-tab-link:hover,
    .mat-tab-link.cdk-focused {
      background: mat.get-color-from-palette($background, list-item-hover-dark);
    }
  }

  &.mat-#{$toolbar-bg-palette-name}-muted {
    background: mat.get-color-from-palette($bg-palette, lighter);
    color: mat.get-color-from-palette($fg-palette, default);

    a:not(.mat-button-base),
    .mat-tab-link,
    .mc-link,
    .mat-link.mc-inline-button:not(.mc-inline-button-disabled) {
      opacity: 1;
      color: mat.get-color-from-palette($link-palette, default);
    }

    .mat-tab-link:focus,
    .mat-tab-link:hover,
    .mat-tab-link.cdk-focused {
      background: mat.get-color-from-palette($background, list-item-hover-dark);
    }
  }
}

@mixin _mc-mat-toolbar($theme, $toolbar-palette-name, $hue: default, $contrast-hue: default-contrast) {
  $palette: map-get($theme, $toolbar-palette-name);

  &.mat-#{$toolbar-palette-name} {
    background: mat.get-color-from-palette($palette, $hue);
    color: mat.get-color-from-palette($palette, $contrast-hue);

    a:not(.mat-button-base),
    .mc-link,
    .mat-link.mc-inline-button:not(.mc-inline-button-disabled) {
      color: inherit;
      text-decoration: underline;
    }
  }
}
