/**
 * mc-site-styles-editor uses this CSS file for theming the custom styles preview.
 * The CSS variables in this file are updated on the fly by JavaScript to reflect the user's customization.
 * The CSS variables are not defined in this file but a default is provided where they are used via `var(--variable, default)`
 * This allows the JavaScript to set the variables to `null` to cause the default value to be used.
 *
 * The API server also uses this file when generating the portal-output custom CSS in a user's privatized output.
 * Sharing this file between the client and server allows us to have one source for the CSS.
 */

/* logo */
.mc-portal-output.mc-portal-output-custom-theme .mc-portal-output-app-logo {
  background-image: var(--mc-portal-output-logo-bg-image, $portal-output-custom-logo-bg-image);
  height: var(--mc-portal-output-logo-height, $portal-output-custom-logo-height);
  width: var(--mc-portal-output-logo-width, $portal-output-custom-logo-width);
}

/* font-family */
.mc-portal-output.mc-portal-output-custom-theme * {
  font-family: var(--mc-portal-output-font-family, #{$portal-output-custom-font-family});
}

/* body background */
.mc-portal-output.mc-portal-output-custom-theme,
.mc-portal-output.mc-portal-output-custom-theme .mc-portal-output-body {
  background: var(--mc-portal-output-body-bg, $portal-output-custom-body-bg);
}

/* card text color */
.mc-portal-output.mc-portal-output-custom-theme .mat-card,
.mc-portal-output.mc-portal-output-custom-theme .mat-card .mc-switch-board .mc-switch-board-bar .mat-list-base .mat-list-item .mat-icon,
.mc-portal-output.mc-portal-output-custom-theme .mat-card .mc-arrow-tabs .mat-tab-header .mat-tab-label-content,
.mc-portal-output.mc-portal-output-custom-theme .mat-card .mat-list-base .mat-list-item,
.mc-portal-output.mc-portal-output-custom-theme .mc-portal-output-body {
  color: var(--mc-portal-output-card-text-color, $portal-output-custom-card-text-color);
}

.mc-portal-output.mc-portal-output-custom-theme .mc-arrow-tabs-border .mat-tab-label.mat-tab-label-active:after,
.mc-portal-output.mc-portal-output-custom-theme .mc-arrow-tabs-border .mat-tab-link.mat-tab-label-active:after {
  border-top-color: var(--mc-portal-output-card-text-color, $portal-output-custom-card-text-color);
  border-left-color: var(--mc-portal-output-card-text-color, $portal-output-custom-card-text-color);
}

.mc-portal-output.mc-portal-output-custom-theme .mc-arrow-tabs-border .mat-tab-list:after {
  border-bottom-color: var(--mc-portal-output-card-text-color, $portal-output-custom-card-text-color);
}

/* card bg color */
.mc-portal-output.mc-portal-output-custom-theme .mat-card,
.mc-portal-output.mc-portal-output-custom-theme .mat-card .mc-arrow-tabs .mat-tab-header,
.mc-portal-output.mc-portal-output-custom-theme .mc-arrow-tabs .mat-tab-header .mat-tab-label.mat-tab-label-active:after,
.mc-portal-output.mc-portal-output-custom-theme .mc-arrow-tabs .mat-tab-header .mat-tab-link.mat-tab-label-active:after,
.mc-portal-output.mc-portal-output-custom-theme .mc-loader {
  background: var(--mc-portal-output-card-bg, $portal-output-custom-card-bg);
}

/* primary button bg color */
.mc-portal-output.mc-portal-output-custom-theme .mat-raised-button.mat-primary {
  background: var(--mc-portal-output-primary-button-bg, $portal-output-custom-primary-button-bg);
}

/* primary button text color */
.mc-portal-output.mc-portal-output-custom-theme .mat-raised-button.mat-primary {
  color: var(--mc-portal-output-primary-button-text-color, $portal-output-custom-primary-button-text-color);
}

/* secondary button bg color */
.mc-portal-output.mc-portal-output-custom-theme .mat-stroked-button {
  border-width: var(--mc-portal-output-secondary-border-width, $portal-output-custom-secondary-border-width);
  background: var(--mc-portal-output-secondary-button-bg, $portal-output-custom-secondary-button-bg);
}

/* secondary button text color */
.mc-portal-output.mc-portal-output-custom-theme .mat-stroked-button {
  color: var(--mc-portal-output-secondary-button-text-color, $portal-output-custom-secondary-button-text-color);
}

/* field text color */
.mc-portal-output.mc-portal-output-custom-theme .mat-form-field.mat-secondary .mat-input-element {
  caret-color: var(--mc-portal-output-field-text-color, $portal-output-custom-field-text-color);
}

.mc-portal-output.mc-portal-output-custom-theme .mat-form-field.mat-secondary .mat-form-field-label,
.mc-portal-output.mc-portal-output-custom-theme .mat-form-field.mat-secondary .mat-form-field-required-marker {
  color: var(--mc-portal-output-field-text-color, $portal-output-custom-field-text-color);
}

/* field focused text color */
.mc-portal-output.mc-portal-output-custom-theme .mat-form-field.mat-secondary.mat-focused .mat-form-field-label,
.mc-portal-output.mc-portal-output-custom-theme .mat-form-field.mat-secondary.mat-focused .mat-form-field-required-marker {
  color: var(--mc-portal-output-field-focused-text-color, $portal-output-custom-field-focused-text-color);
}

.mc-portal-output.mc-portal-output-custom-theme .mat-form-field.mat-secondary.mat-focused .mat-form-field-ripple {
  background-color: var(--mc-portal-output-field-focused-text-color, $portal-output-custom-field-focused-text-color);
}

.mc-portal-output.mc-portal-output-custom-theme .mc-image-picker-img-button.mat-button.mat-secondary,
.mc-portal-output.mc-portal-output-custom-theme .mc-image-picker-actions .mat-button.mat-secondary {
  color: var(--mc-portal-output-field-focused-text-color, $portal-output-custom-field-focused-text-color);
}

/* field invalid text color */
.mc-portal-output.mc-portal-output-custom-theme .mat-form-field.mat-secondary.mat-form-field-invalid .mat-form-field-label,
.mc-portal-output.mc-portal-output-custom-theme .mat-form-field.mat-secondary.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker,
.mc-portal-output.mc-portal-output-custom-theme .mat-form-field.mat-secondary.mat-form-field-invalid.mat-focused .mat-form-field-label,
.mc-portal-output.mc-portal-output-custom-theme .mat-form-field.mat-secondary.mat-form-field-invalid.mat-focused .mat-form-field-label .mat-form-field-required-marker {
  color: var(--mc-portal-output-field-invalid-text-color, $portal-output-custom-field-invalid-text-color);
}

.mc-portal-output.mc-portal-output-custom-theme .mat-form-field.mat-secondary.mat-form-field-invalid .mat-form-field-ripple,
.mc-portal-output.mc-portal-output-custom-theme .mat-form-field.mat-secondary.mat-form-field-invalid.mat-focused .mat-form-field-ripple {
  background-color: var(--mc-portal-output-field-invalid-text-color, $portal-output-custom-field-invalid-text-color);
}

.mc-portal-output.mc-portal-output-custom-theme .mat-error,
.mc-portal-output.mc-portal-output-custom-theme .mat-card mc-errors-expansion-panel[compact] .mc-errors-expansion-panel-general-message {
  color: var(--mc-portal-output-field-invalid-text-color, $portal-output-custom-field-invalid-text-color);
}

/* checkbox color */
.mc-portal-output.mc-portal-output-custom-theme .mat-checkbox-checked.mat-accent-vivid .mat-checkbox-background,
.mc-portal-output.mc-portal-output-custom-theme .mat-checkbox-indeterminate.mat-accent-vivid .mat-checkbox-background {
  background-color: var(--mc-portal-output-checkbox-color, $portal-output-custom-checkbox-color);
}

/* link text color */
.mc-portal-output.mc-portal-output-custom-theme a,
.mc-portal-output.mc-portal-output-custom-theme .mc-link,
.mc-portal-output.mc-portal-output-custom-theme .mc-link-button {
  color: var(--mc-portal-output-link-color, $portal-output-custom-link-color);
}

/* link muted text color */
.mc-portal-output.mc-portal-output-custom-theme .link-muted {
  color: var(--mc-portal-output-link-muted-color, $portal-output-custom-link-muted-color);
}

/* loader color */
.mc-portal-output.mc-portal-output-custom-theme .mat-progress-spinner circle {
  stroke: var(--mc-portal-output-loader-color, $portal-output-custom-loader-color);
}

/* snack bar bg color */
.mc-portal-output.mc-portal-output-custom-theme .mat-snack-bar-container {
  background-color: var(--mc-portal-output-snackbar-bg, $portal-output-custom-snackbar-bg);
}

/* snack bar text color */
.mc-portal-output.mc-portal-output-custom-theme .mat-snack-bar-container {
  color: var(--mc-portal-output-snackbar-text-color, $portal-output-custom-snackbar-text-color);
}

/* snack bar button text color */
.mc-portal-output.mc-portal-output-custom-theme .mat-simple-snackbar-action {
  color: var(--mc-portal-output-snackbar-button-text-color, $portal-output-custom-snackbar-button-text-color);
}
