@use '@angular/material' as mat;

@mixin mc-switch-boards-theme ($theme, $typography) {
  .mc-switch-board {
    @include _mc-switch-boards-theme($theme, 'secondary', ripple-dark);
    @include _mc-switch-boards-theme($theme, 'secondary-invert');
  }
}

@mixin _mc-switch-boards-theme($theme, $palette-name, $ripple-color-name: null) {
  @include _mc-switch-boards($theme, map-get($theme, $palette-name), $palette-name, default, default-contrast, $ripple-color-name);
  @include _mc-switch-boards($theme, map-get($theme, $palette-name), $palette-name + '-muted', lighter, default-contrast, $ripple-color-name);
  @include _mc-switch-boards($theme, map-get($theme, $palette-name), $palette-name + '-vivid', darker, default-contrast, $ripple-color-name);
}

@mixin _mc-switch-boards ($theme, $palette, $name, $hue, $contrast-hue, $ripple-color-name) {
  $link: map-get($theme, link);

  &.mat-#{$name} {
    .mc-switch-board-bar {
      background: mat.get-color-from-palette($palette, $hue);
      color: mat.get-color-from-palette($palette, $contrast-hue);

      a:not(.mat-list-item),
      .mc-link,
      .mc-inline-button:not(.mc-inline-button-disabled).mat-link {
        color: mat.get-color-from-palette($palette, $contrast-hue);
        text-decoration: underline;

        &:hover {
          color: mat.get-color-from-palette($link);
        }
      }

      .mat-list-base .mat-list-item {
        color: mat.get-color-from-palette($palette, $contrast-hue);

        @if ($ripple-color-name) {
          .mat-list-item-ripple .mat-ripple-element, // Targets matRipple automatically added by the mat-list
          &.mat-ripple > .mat-ripple-element { // Targets matRipple manually on the mat-list-item
            background-color: mat.get-color-from-palette(map-get($theme, background), $ripple-color-name);
          }
        }
      }
    }
  }
}
