@use '@angular/material' as mat;

@mixin mc-angle-pickers-theme ($theme, $typography) {
  $foreground: map-get($theme, foreground);

  // Default (unfocused colors)
  .mc-angle-picker {
    border-color: mat.get-color-from-palette($foreground, secondary-text);

    .mc-angle-picker-dot,
    .mc-angle-picker-line {
      background: mat.get-color-from-palette($foreground, secondary-text);
    }
  }

  // Focused colors
  @include _mc-angle-picker-palette($theme, 'primary');
  @include _mc-angle-picker-palette($theme, 'accent');
  @include _mc-angle-picker-palette($theme, 'warn');
  @include _mc-angle-picker-palette($theme, 'secondary');
}

@mixin _mc-angle-picker-palette ($theme, $color-name) {
  $palette: map-get($theme, $color-name);

  .mc-angle-picker.cdk-focused {
    &.mat-#{$color-name} {
      border-color: mat.get-color-from-palette($palette, default);

      .mc-angle-picker-dot,
      .mc-angle-picker-line {
        background: mat.get-color-from-palette($palette, default);
      }
    }

    &.mat-#{$color-name}-muted {
      border-color: mat.get-color-from-palette($palette, lighter);

      .mc-angle-picker-dot,
      .mc-angle-picker-line {
        background: mat.get-color-from-palette($palette, lighter);
      }
    }

    &.mat-#{$color-name}-vivid {
      border-color: mat.get-color-from-palette($palette, darker);

      .mc-angle-picker-dot,
      .mc-angle-picker-line {
        background: mat.get-color-from-palette($palette, darker);
      }
    }
  }
}
