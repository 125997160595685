@use '@angular/material' as mat;

@include mat.datepicker-date-range-colors(hotpink, teal, yellow, purple);

@mixin mc-mat-datepickers-theme ($theme, $typography) {
  @include _mc-mat-datepicker($theme, 'primary');
  @include _mc-mat-datepicker($theme, 'secondary');
}

@mixin _mc-mat-datepicker ($theme, $palette-name) {
  $palette: map-get($theme, $palette-name);
  $foreground: map-get($theme, foreground);

  .mat-datepicker-content {
    &.mat-#{$palette-name} {
      .mat-calendar-body-selected {
        background-color: mat.get-color-from-palette($palette, default);
        color: mat.get-color-from-palette($palette, default-contrast);
      }

      .mat-calendar-body-disabled>.mat-calendar-body-selected {
        background-color: fade-out(mat.get-color-from-palette($palette, default), 0.6);
      }

      .mat-calendar-body-today.mat-calendar-body-selected {
        box-shadow: inset 0 0 0 1px mat.get-color-from-palette($palette, default-contrast);
      }

      .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover {
        > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
          background-color: mat.get-color-from-palette($palette, lighter);
        }
      }

      .cdk-keyboard-focused,
      .cdk-program-focused {
        .mat-calendar-body-active {
          > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
            background-color: mat.get-color-from-palette($palette, lighter);
          }
        }
      }
    }

    &.mat-#{$palette-name}-muted {
      .mat-calendar-body-selected {
        background-color: mat.get-color-from-palette($palette, lighter);
        color: mat.get-color-from-palette($palette, lighter-contrast);
      }

      .mat-calendar-body-disabled>.mat-calendar-body-selected {
        background-color: fade-out(mat.get-color-from-palette($palette, lighter), 0.6);
      }

      .mat-calendar-body-today.mat-calendar-body-selected {
        box-shadow: inset 0 0 0 1px mat.get-color-from-palette($palette, lighter-contrast);
      }
    }

    &.mat-#{$palette-name}-vivid {
      .mat-calendar-body-selected {
        background-color: mat.get-color-from-palette($palette, darker);
        color: mat.get-color-from-palette($palette, darker-contrast);
      }

      .mat-calendar-body-disabled>.mat-calendar-body-selected {
        background-color: fade-out(mat.get-color-from-palette($palette, darker), 0.6);
      }

      .mat-calendar-body-today.mat-calendar-body-selected {
        box-shadow: inset 0 0 0 1px mat.get-color-from-palette($palette, darker-contrast);
      }
    }
  }

  .mat-datepicker-toggle {
    .mat-mdc-icon-button[disabled] {
      color: mat.get-color-from-palette($foreground, text-muted);
    }
  }

}
