@use '@angular/material' as mat;

@mixin mc-mat-progress-spinners-theme ($theme, $typography) {
  .mat-progress-spinner,
  .mat-spinner {
    @include _mc-mat-progress-spinner($theme, 'accent');
  }
}

@mixin _mc-mat-progress-spinner ($theme, $palette-name) {
  $palette: map-get($theme, $palette-name);

  &.mat-#{$palette-name} {
    circle {
      stroke: mat.get-color-from-palette($palette);
    }
  }

  &.mat-#{$palette-name}-vivid {
    circle {
      stroke: mat.get-color-from-palette($palette, darker);
    }
  }

  &.mat-#{$palette-name}-muted {
    circle {
      stroke: mat.get-color-from-palette($palette, lighter);
    }
  }
}
