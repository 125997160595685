@use '@angular/material' as mat;

@mixin mc-mat-snack-bars-theme ($theme, $typography) {
  .mc-snack-bar {
    @include _mc-mat-snack-bar($theme, 'secondary');
  }
}

@mixin _mc-mat-snack-bar ($theme, $palette-name) {
  $palette: map-get($theme, $palette-name);

  &.mat-snack-bar-container {
    background: mat.get-color-from-palette($palette, default);
    color: mat.get-color-from-palette($palette, default-contrast);
  }

  .mat-simple-snackbar-action {
    color: mat.get-color-from-palette($palette, default-contrast);
  }
}
