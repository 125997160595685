@use '@angular/material' as mat;

@mixin mc-mat-checkboxes-theme ($theme, $typography) {
  $background: map-get($theme, background);

  @include _mc-mat-checkbox($theme, 'primary');
  @include _mc-mat-checkbox($theme, 'accent');
  @include _mc-mat-checkbox($theme, 'warn');
  @include _mc-mat-checkbox($theme, 'secondary');
  @include _mc-mat-checkbox($theme, 'basic');

  .mat-checkbox-disabled:not(.mc-checkbox-colored-disabled) {

    &.mat-checkbox-checked,
    &.mat-checkbox-indeterminate {
      .mat-checkbox-background {
        background-color: mat.get-color-from-palette($background, disabled-checkbox);
      }
    }
  }
}

@mixin _mc-mat-checkbox ($theme, $palette-name) {
  $palette: map-get($theme, $palette-name);

  .mat-#{$palette-name} {

    &.mat-checkbox-checked,
    &.mat-checkbox-indeterminate {
      .mat-checkbox-background {
        background-color: mat.get-color-from-palette($palette, default);
      }
    }

    &.mc-checkbox-colored-frame {
      .mat-checkbox-frame {
        border-color: mat.get-color-from-palette($palette, default);
      }
    }
  }

  .mat-#{$palette-name}-muted {

    &.mat-checkbox-checked,
    &.mat-checkbox-indeterminate {
      .mat-checkbox-background {
        background-color: mat.get-color-from-palette($palette, lighter);
      }
    }

    &.mc-checkbox-colored-frame {
      .mat-checkbox-frame {
        border-color: mat.get-color-from-palette($palette, lighter);
      }
    }
  }

  .mat-#{$palette-name}-vivid {

    &.mat-checkbox-checked,
    &.mat-checkbox-indeterminate {
      .mat-checkbox-background {
        background-color: mat.get-color-from-palette($palette, darker);
      }
    }

    &.mc-checkbox-colored-frame {
      .mat-checkbox-frame {
        border-color: mat.get-color-from-palette($palette, darker);
      }
    }
  }
}
