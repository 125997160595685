@use '@angular/material' as mat;

@mixin mc-mat-lists-theme ($theme, $typography) {
  @include _mc-mat-list($theme, 'primary');
  @include _mc-mat-list($theme, 'secondary');

  @include _mc-mat-selection-list($theme, 'accent');
}

@mixin _mc-mat-list ($theme, $palette-name) {
  $foreground: map-get($theme, foreground);
  $palette: map-get($theme, $palette-name);

  .mat-list-base {
    .mat-list-item-disabled {
      color: mat.get-color-from-palette($foreground, disabled-text);
      background-color: transparent;

      .mat-pseudo-checkbox {
        color: mat.get-color-from-palette($foreground, disabled-text);
      }
    }
  }
}

@mixin _mc-mat-selection-list ($theme, $palette-name) {
  $foreground: map-get($theme, foreground);
  $palette: map-get($theme, $palette-name);

  .mat-selection-list {
    &.mat-#{$palette-name} {
      .mat-pseudo-checkbox-checked,
      .mat-pseudo-checkbox-indeterminate {
        background: mat.get-color-from-palette($palette, default);
      }
    }

    &.mat-#{$palette-name}-muted {
      .mat-pseudo-checkbox-checked,
      .mat-pseudo-checkbox-indeterminate {
        background: mat.get-color-from-palette($palette, lighter);
      }
    }

    &.mat-#{$palette-name}-vivid {
      .mat-pseudo-checkbox-checked,
      .mat-pseudo-checkbox-indeterminate {
        background: mat.get-color-from-palette($palette, darker);
      }
    }
  }
}
