@use '@angular/material' as mat;
@mixin mc-mat-progress-bars-theme ($theme, $typography) {
  .mat-progress-bar {
    @include _mc-mat-progress-bar($theme, 'accent');
    @include _mc-mat-progress-bar($theme, 'secondary');
    @include _mc-mat-progress-bar($theme, 'notice');
    @include _mc-mat-progress-bar($theme, 'caution');
    @include _mc-mat-progress-bar($theme, 'info');
    @include _mc-mat-progress-bar($theme, 'info-2');
    @include _mc-mat-progress-bar($theme, 'build-cancel');
    @include _mc-mat-progress-bar($theme, 'build-fail');
    @include _mc-mat-progress-bar($theme, 'build-success');
    @include _mc-mat-progress-bar($theme, 'build-unknown');
    @include _mc-mat-progress-bar($theme, 'task-low-priority');
    @include _mc-mat-progress-bar($theme, 'task-medium-priority');
    @include _mc-mat-progress-bar($theme, 'task-high-priority');
    @include _mc-mat-progress-bar($theme, 'usage-storage');
    @include _mc-mat-progress-bar($theme, 'usage-source-files');
    @include _mc-mat-progress-bar($theme, 'usage-builds');
    @include _mc-mat-progress-bar($theme, 'usage-tasks');
    @include _mc-mat-progress-bar($theme, 'usage-misc');
    @include _mc-mat-progress-bar($theme, 'usage-authors');
    @include _mc-mat-progress-bar($theme, 'usage-smes');
    @include _mc-mat-progress-bar($theme, 'usage-viewers');
  }
}

@mixin _mc-mat-progress-bar ($theme, $progress-bar-name) {
  $palette: map-get($theme, $progress-bar-name);

  &.mat-#{$progress-bar-name} {
    .mat-progress-bar-background {
      color: mat.get-color-from-palette($palette, lighter);
    }

    .mat-progress-bar-buffer {
      background-color: mat.get-color-from-palette($palette, lighter);
    }

    .mat-progress-bar-fill::after {
      background-color: mat.get-color-from-palette($palette, default);
    }
  }

  &.mat-#{$progress-bar-name}-vivid {
    .mat-progress-bar-background {
      color: mat.get-color-from-palette($palette, lighter);;
    }

    .mat-progress-bar-buffer {
      background-color: mat.get-color-from-palette($palette, lighter);;
    }

    .mat-progress-bar-fill::after {
      background-color: mat.get-color-from-palette($palette, darker);
    }
  }
}
