/* The theme is being hard-coded to the mc-peacock-theme. This should always match the theme being used by portal-output */
@use '@angular/material' as mat;

$portal-output-custom-theme: map-get($mc-peacock-theme, core);
$portal-output-custom-accent: map-get($portal-output-custom-theme, accent);
$portal-output-custom-foreground: map-get($portal-output-custom-theme, foreground);
$portal-output-custom-link: map-get($portal-output-custom-theme, link);
$portal-output-custom-primary: map-get($portal-output-custom-theme, primary);
$portal-output-custom-secondary: map-get($portal-output-custom-theme, secondary);
$portal-output-custom-warn: map-get($portal-output-custom-theme, warn);

/* The following variables are all settable as part of a Site Theme */
$portal-output-custom-logo-bg-image: none;
$portal-output-custom-logo-height: 0;
$portal-output-custom-logo-width: 0;
$portal-output-custom-font-family: $default-font-family;
$portal-output-custom-body-bg: $white;
$portal-output-custom-card-text-color: $text-color-strong;
$portal-output-custom-card-bg: $white;
$portal-output-custom-primary-button-bg: mat.get-color-from-palette($portal-output-custom-primary, default);
$portal-output-custom-primary-button-text-color: mat.get-color-from-palette($portal-output-custom-primary, default-contrast);
$portal-output-custom-secondary-border-width: 1px;
$portal-output-custom-secondary-button-bg: transparent;
$portal-output-custom-secondary-button-text-color: inherit;
$portal-output-custom-field-text-color: mat.get-color-from-palette($portal-output-custom-foreground, secondary-text);
$portal-output-custom-field-focused-text-color: mat.get-color-from-palette($portal-output-custom-secondary, default);
$portal-output-custom-field-invalid-text-color: mat.get-color-from-palette($portal-output-custom-warn, default);
$portal-output-custom-checkbox-color: mat.get-color-from-palette($portal-output-custom-accent, darker);
$portal-output-custom-link-color: mat.get-color-from-palette($portal-output-custom-link, default);
$portal-output-custom-link-muted-color: mat.get-color-from-palette($portal-output-custom-foreground, link-muted);
$portal-output-custom-loader-color: mat.get-color-from-palette($portal-output-custom-accent, darker);
$portal-output-custom-snackbar-bg: mat.get-color-from-palette($portal-output-custom-secondary, default);
$portal-output-custom-snackbar-text-color: mat.get-color-from-palette($portal-output-custom-secondary, default-contrast);
$portal-output-custom-snackbar-button-text-color: mat.get-color-from-palette($portal-output-custom-secondary, default-contrast);
