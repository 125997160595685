// Base Colors
$black: #000;
$gray-dark: #292a29 !default; // Headings, List Items, Table Items, Tabs, Editor Info Bar, Navbar, Form Controls, List Headers, Grid Headers, Content Text
$gray: #505050 !default; // Buttons (default), Main Nav (text on page bg), Page Gutter (text on page bg)
$gray-light: #767676 !default; // Profile Card Headings, Profile Card Sub-Headings, Buttons (less important), Paging Controls (less important), Content Text
$gray-x-light: #b5b5b5 !default; // Labels (Storage Usage), muted
$gray-xx-light: #cacaca !default; // List Item and Grid Item Dividers
$gray-xxx-light: #d0d3d5 !default; // Page BG
$gray-xxxx-light: #eee !default; // Toolbar BG
$gray-xxxxx-light: #f2f2f2;
$off-white: #f6f8fc;
$white: #fff !default; // Navbar, Things with a Dark BG or Color BG
$blue-light: #39a0d7 !default;
$blue-x-light: rgb(0 166 194 / 15%) !default;
$blue-xx-light: rgb(0 166 194 / 10%) !default;
$blue-dark: #036e87 !default;
$blue-x-dark: #02667d !default;

/*
 * Text Colors
 *
 * General Guidelines
 *  UI - lighter gray
 *  Content (default text color) - lighter gray
 *  List/Grid Items, Editor, Message Center - dark gray
 */
$text-color-strong: $gray-dark !default;
$text-color-normal: $gray !default;
$text-color-weak: $gray-light !default;
$text-color-muted: $gray-x-light !default;
$text-color-invert: $white !default;
$default-text-color: $text-color-normal !default;
