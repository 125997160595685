@use '@angular/material' as mat;

@mixin mc-split-dialogs-theme ($theme, $typography) {
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $secondary: map-get($theme, secondary);

  .mc-vertical-split-dialog {
    color: mat.get-color-from-palette($foreground, vertical-split-dialog);

    .mat-dialog-title {
      background-color: mat.get-color-from-palette($secondary, default);
      color: mat.get-color-from-palette($secondary, default-contrast);
    }
  }
}
