@use '@angular/material' as mat;

@mixin mc-cards-theme ($theme, $typography) {
  $primary: map-get($theme, primary);
  $secondary: map-get($theme, secondary);

  .split-card-header {
    background: mat.get-color-from-palette($secondary, default);
    color: mat.get-color-from-palette($primary);
  }
}
