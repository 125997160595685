@use '@angular/material' as mat;

@mixin mc-tabs-theme ($theme, $typography) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .mat-tab-group {
    @include _mc-tab-group-theme($theme, 'secondary', 'secondary-invert');
    @include _mc-tab-group-theme($theme, 'basic', 'secondary');

    // Set the typography shared throughout the tab group
    &,
    .mat-tab-label {
      @include mat.typography-level($typography, navbar);
    }
  }

  .mc-arrow-tabs {

    // Remove the bottom border from the tabs
    .mat-tab-header {
      border-bottom: $border-none;
    }

    // Make the labels fully opaque
    .mat-tab-label {
      opacity: 1;
    }
  }

  // Default to the card background color as this is the most common use case
  @include mc-arrow-tabs-nub-color(mat.get-color-from-palette($background, card));

  .mc-arrow-tabs-border {

    // The border under the tab labels
    .mat-tab-list:after {
      border-bottom-color: map-get($foreground, divider);
    }

    // The border on the arrow indicator
    .mat-tab-label,
    .mat-tab-link {
      &.mat-tab-label-active:after {
        border-top-color: map-get($foreground, divider);
        border-left-color: map-get($foreground, divider);
      }
    }
  }
}

@mixin _mc-tab-group-theme ($theme, $toolbar-bg-palette-name, $toolbar-fg-palette-name, $toolbar-fg-color-name: default) {
  $background: map-get($theme, background);
  $bg-palette: map-get($theme, $toolbar-bg-palette-name);
  $fg-palette: map-get($theme, $toolbar-fg-palette-name);

  &.mat-#{$toolbar-bg-palette-name} {
    .mat-tab-header {
      background-color: mat.get-color-from-palette($bg-palette, default);
    }

    .mat-tab-label-content {
      color: mat.get-color-from-palette($fg-palette, $toolbar-fg-color-name);
    }

    .mat-tab-label:focus,
    .mat-tab-label:hover {
      &:not(.mat-tab-disabled) {
        background: mat.get-color-from-palette($bg-palette, darker);
      }
    }
  }

  &.mat-#{$toolbar-bg-palette-name}-muted {
    .mat-tab-header {
      background-color: mat.get-color-from-palette($bg-palette, lighter);
    }

    .mat-tab-label-content {
      color: mat.get-color-from-palette($fg-palette, lighter);
    }

    .mat-tab-label:focus,
    .mat-tab-label:hover {
      &:not(.mat-tab-disabled) {
        background: mat.get-color-from-palette($background, lighter);
      }
    }
  }

  &.mat-#{$toolbar-bg-palette-name}-vivid {
    .mat-tab-header {
      background-color: mat.get-color-from-palette($bg-palette, darker);
    }

    .mat-tab-label-content {
      color: mat.get-color-from-palette($fg-palette, darker);
    }

    .mat-tab-label:focus,
    .mat-tab-label:hover {
      &:not(.mat-tab-disabled) {
        background: mat.get-color-from-palette($background, darker);
      }
    }
  }
}
