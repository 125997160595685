@use '@angular/material' as mat;

@mixin mc-mat-form-fields-theme ($theme, $typography) {
  @include _mc-mat-form-field($theme, 'primary');
  @include _mc-mat-form-field($theme, 'secondary');
  @include _mc-mat-form-field($theme, 'secondary-invert');

  /**
   * .mc-form-field-type-button
   * Styles a mat-form-field that uses a button for its control (eg mc-file-picker)
   */
  .mc-form-field-type-button {
    @include _mc-form-field-type-button-palette-theme($theme, 'primary');
    @include _mc-form-field-type-button-palette-theme($theme, 'accent');
    @include _mc-form-field-type-button-palette-theme($theme, 'warn');
    @include _mc-form-field-type-button-palette-theme($theme, 'secondary');
  }
}

@mixin _mc-mat-form-field ($theme, $palette-name) {
  $palette: map-get($theme, $palette-name);
  $notice: map-get($theme, notice);
  $warn: map-get($theme, warn);

  $underline-color-warn: mat.get-color-from-palette($warn);

  .mat-form-field.mat-focused {
    &.mat-#{$palette-name} {
      .mat-form-field-label,
      .mat-form-field-required-marker {
        color: mat.get-color-from-palette($palette, default);
      }

      .mat-form-field-ripple {
        background-color: mat.get-color-from-palette($palette, default);
      }
    }

    &.mat-#{$palette-name}-muted {
      .mat-form-field-label,
      .mat-form-field-required-marker {
        color: mat.get-color-from-palette($palette, lighter);
      }

      .mat-form-field-ripple {
        background-color: mat.get-color-from-palette($palette, lighter);
      }
    }

    &.mat-#{$palette-name}-vivid {
      .mat-form-field-label,
      .mat-form-field-required-marker {
        color: mat.get-color-from-palette($palette, darker);
      }

      .mat-form-field-ripple {
        background-color: mat.get-color-from-palette($palette, darker);
      }
    }
  }

  .mat-form-field.mat-form-field-invalid,
  .mat-form-field.mat-form-field-invalid.mat-focused {
    &.mat-#{$palette-name},
    &.mat-#{$palette-name}-muted,
    &.mat-#{$palette-name}-vivid {
      .mat-form-field-label,
      .mat-form-field-label .mat-form-field-required-marker {
        color: $underline-color-warn;
      }

      .mat-form-field-ripple {
        background-color: $underline-color-warn;
      }
    }
  }

  // mat-form-field-appearance-outline
  .mat-form-field-appearance-outline.mat-focused {
    &.mat-#{$palette-name} {
      .mat-form-field-outline-thick {
        color: mat.get-color-from-palette($palette, default);
      }
    }

    &.mat-#{$palette-name}-muted {
      .mat-form-field-outline-thick {
        color: mat.get-color-from-palette($palette, lighter);
      }
    }

    &.mat-#{$palette-name}-vivid {
      .mat-form-field-outline-thick {
        color: mat.get-color-from-palette($palette, darker);
      }
    }
  }

  // datepicker toggle
  .mat-form-field {
    &.mat-#{$palette-name} {
      .mat-datepicker-toggle-active {
        color: mat.get-color-from-palette($palette, default);
      }
    }

    &.mat-#{$palette-name}-muted {
      .mat-datepicker-toggle-active {
        color: mat.get-color-from-palette($palette, lighter);
      }
    }

    &.mat-#{$palette-name}-vivid {
      .mat-datepicker-toggle-active {
        color: mat.get-color-from-palette($palette, darker);
      }
    }
  }

  // mat-hint
  .mat-form-field .mat-hint {
    &.mc-hint-success {
      color: mat.get-color-from-palette($notice, default);
    }
  }

  // select
  .mat-form-field.mat-focused {
    &.mat-#{$palette-name} {
      .mat-select-arrow {
        color: mat.get-color-from-palette($palette, default);
      }
    }

    &.mat-#{$palette-name}-muted {
      .mat-select-arrow {
        color: mat.get-color-from-palette($palette, lighter);
      }
    }

    &.mat-#{$palette-name}-vivid {
      .mat-select-arrow {
        color: mat.get-color-from-palette($palette, darker);
      }
    }
  }
}

@mixin _mc-form-field-type-button-palette-theme ($theme, $palette-name) {
  $palette: map-get($theme, $palette-name);

  &.mat-#{$palette-name} {
    @include _mc-form-field-type-button-theme($palette, default-contrast);
  }

  &.mat-#{$palette-name}-muted {
    @include _mc-form-field-type-button-theme($palette, lighter-contrast);
  }

  &.mat-#{$palette-name}-vivid {
    @include _mc-form-field-type-button-theme($palette, darker-contrast);
  }
}

@mixin _mc-form-field-type-button-theme ($palette, $contrast-name) {
  &.mat-form-field-appearance-legacy {
    .mc-form-control-empty + .mat-form-field-label-wrapper {
      .mat-form-field-label,
      .mat-form-field-required-marker {
        color: mat.get-color-from-palette($palette, $contrast-name);
      }
    }
  }

  &.mat-form-field-invalid {
    .mc-form-control-empty + .mat-form-field-label-wrapper {
      .mat-form-field-label,
      .mat-form-field-required-marker {
        color: mat.get-color-from-palette($palette, $contrast-name);
      }
    }
  }
}
