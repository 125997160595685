@use '@angular/material' as mat;
@mixin mc-mat-pseudo-checkboxes-theme ($theme, $typography) {
  $background: map-get($theme, background);

  @include _mc-mat-pseudo-checkbox($theme, 'primary');
  @include _mc-mat-pseudo-checkbox($theme, 'accent');
  @include _mc-mat-pseudo-checkbox($theme, 'secondary');

  .mat-pseudo-checkbox-disabled {
    &.mat-pseudo-checkbox-checked,
    &.mat-pseudo-checkbox-indeterminate {
      background-color: mat.get-color-from-palette($background, disabled-checkbox);
    }
  }
}

@mixin _mc-mat-pseudo-checkbox ($theme, $palette-name) {
  $palette: map-get($theme, $palette-name);

  .mat-#{$palette-name} {
    &.mat-pseudo-checkbox-checked,
    &.mat-pseudo-checkbox-indeterminate {
      background: mat.get-color-from-palette($palette, default);
    }
  }

  .mat-#{$palette-name}-muted {
    &.mat-pseudo-checkbox-checked,
    &.mat-pseudo-checkbox-indeterminate {
      background: mat.get-color-from-palette($palette, lighter);
    }
  }

  .mat-#{$palette-name}-vivid {
    &.mat-pseudo-checkbox-checked,
    &.mat-pseudo-checkbox-indeterminate {
      background: mat.get-color-from-palette($palette, darker);
    }
  }
}
