.mc-material {
  .mat-toolbar {
    .mat-button,
    .mat-flat-button {
      font-size: $font-size-normal;

      &.mc-button-small {
        font-size: $font-size-small;
      }
    }

    .mat-divider {
      height: $toolbar-divider-height;
      margin-right: $toolbar-divider-spacing;
      margin-left: $toolbar-divider-spacing;
    }

    // Style mat-tab-nav-bar to work in a mat-toolbar
    .mat-tab-nav-bar {
      height: 100%;
      border-bottom: $border-none;

      .mat-tab-links {
        height: 100%;
      }

      .mat-tab-link {
        height: 100%;
        min-width: 0;
        padding: $spacing-none $toolbar-tab-spacing;
      }
    }
  }
}
