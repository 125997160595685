@use '@angular/material' as mat;

@mixin mc-mat-expansion-panels-theme ($theme, $typography) {
  .mat-expansion-panel {
    @include _mc-mat-expansion-panels($theme, 'secondary');
  }
}

@mixin _mc-mat-expansion-panels ($theme, $bg-palette-name) {
  $bg-palette: map-get($theme, $bg-palette-name);

  &.mat-#{$bg-palette-name} {
    .mat-expansion-panel-header {
      background: mat.get-color-from-palette($bg-palette, default);
      color: mat.get-color-from-palette($bg-palette, default-contrast);
    }

    .mat-expansion-indicator:after {
      color: mat.get-color-from-palette($bg-palette, default-contrast);
    }

    &:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
      background: mat.get-color-from-palette($bg-palette, darker);
    }
  }
}
