@use '@angular/material' as mat;

@mixin mc-mat-radio-buttons-theme ($theme, $typography) {
  @include _mc-mat-radio-button($theme, 'primary');
  @include _mc-mat-radio-button($theme, 'accent');
  @include _mc-mat-radio-button($theme, 'warn');
  @include _mc-mat-radio-button($theme, 'secondary');
  @include _mc-mat-radio-button($theme, 'basic');
}

@mixin _mc-mat-radio-button ($theme, $palette-name) {
  $palette: map-get($theme, $palette-name);

  .mat-radio-button {
    &.mat-#{$palette-name} {
      &.mat-radio-checked .mat-radio-outer-circle {
        border-color: mat.get-color-from-palette($palette, default);
      }

      .mat-radio-inner-circle,
      .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
      &.mat-radio-checked .mat-radio-persistent-ripple,
      &:active .mat-radio-persistent-ripple {
        background-color: mat.get-color-from-palette($palette, default);
      }

      &.mc-radio-colored-outer-circle {
        .mat-radio-outer-circle {
          border-color: mat.get-color-from-palette($palette, default);
        }
      }
    }

    &.mat-#{$palette-name}-muted {
      &.mat-radio-checked .mat-radio-outer-circle {
        border-color: mat.get-color-from-palette($palette, lighter);
      }

      .mat-radio-inner-circle,
      .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
      &.mat-radio-checked .mat-radio-persistent-ripple,
      &:active .mat-radio-persistent-ripple {
        background-color: mat.get-color-from-palette($palette, lighter);
      }

      &.mc-radio-colored-outer-circle {
        .mat-radio-outer-circle {
          border-color: mat.get-color-from-palette($palette, lighter);
        }
      }
    }

    &.mat-#{$palette-name}-vivid {
      &.mat-radio-checked .mat-radio-outer-circle {
        border-color: mat.get-color-from-palette($palette, darker);
      }

      .mat-radio-inner-circle,
      .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
      &.mat-radio-checked .mat-radio-persistent-ripple,
      &:active .mat-radio-persistent-ripple {
        background-color: mat.get-color-from-palette($palette, darker);
      }

      &.mc-radio-colored-outer-circle {
        .mat-radio-outer-circle {
          border-color: mat.get-color-from-palette($palette, darker);
        }
      }
    }
  }
}
