@use '@angular/material' as mat;

@mixin mc-gradients-theme ($theme, $typography) {
  $background: map-get($theme, background);
  $secondary: map-get($theme, secondary);
  $primary: map-get($theme, primary);

  .mc-brand-gradient {
    background-image: linear-gradient(45deg, rgba(mat.get-color-from-palette($secondary, default), 90%), rgba(mat.get-color-from-palette($primary, default), 50%));
  }

  .mc-component-gradient {
    background-image: linear-gradient(rgb(255 255 255 / 0%) -.9rem, mat.get-color-from-palette($background, component));
  }
}
